// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  IconButton,
  FormControlLabel,
  TextField,
  InputLabel,
  Radio,
  Button,
  MenuItem,
  Collapse,
  TextareaAutosize,
  withStyles,
  Tooltip,
  CircularProgress
} from "@material-ui/core";
import { colors } from "../../../blocks/utilities/src/Colors";
import {
  DeleteProductIcon,
  IconCalender,
  cartEmptyImage,
  customerAddIcon,
  customerViewIcon,
} from "./assets";
import {
  InputField,
  MultiSelectionBox,
  CustomMenuItem,
  CustomFormControl,
} from "../../../components/src/customComponents/CustomTags.web";
import { Autocomplete } from "@material-ui/lab";
import { ExpandLess, ExpandMore, HelpOutlined } from "@material-ui/icons";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { StyledRadio } from "../../../components/src/customComponents/RadioDropdown.web";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/moment";
import QuickDrop from "../../../blocks/OrderCreation/src/QuickDrop.web";
import ShoppingCartController, {
  Props,
  configJSON,
} from "./ShoppingCartController.web";
import AddCustomerModal from "../../../blocks/email-account-registration/src/AddCustomerModal.web";
import { CustomSnackbar } from "../../../components/src/customComponents/CustomSnackbar.web";
import ShowCustomerDetailsModal from "../../../blocks/email-account-registration/src/ShowCustomerDetailsModal.web";
import PaymentModal from "../../../blocks/Payments/src/PaymentModal.web";
import {
  ICustomer,
  IOrder,
  ORDER_TYPES,
} from "../../../blocks/OrderCreation/src/OrderCreation2Controller.web";
import CustomSwitch from "../../../components/src/CustomSwitch.web";
import {
  IChargeListItem,
  OrderItemPreferences,
  PreferemcesItem,
  Specifications,
  UniqueCombination,
} from "../../../components/src/Interface";
import OrderSummary from "../../../blocks/OrderCreation/src/OrderSummary.web";
import CustomLoader from "../../../components/src/customComponents/CustomLoader.web";
// Customizable Area End

// Customizable Area Start
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
// Customizable Area End

export default class ShoppingCart extends ShoppingCartController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderCustomerSearch() {
    const { countryCodeInput, customerPhoneNumber, customerList } = this.state;
    return (
      <Box className='search-customer-wrap'>
        <InputField
          variant='outlined'
          value={countryCodeInput}
          className='countryInputDigit'
          inputProps={{
            "data-test-id": "field-country-code",
          }}
          onChange={(event: React.ChangeEvent<{ value: string }>) =>
            this.handleChangeCountryCode(event.target.value)
          }
        />
        <StyledAutoComplete
          data-test-id='field-customer-phone'
          noOptionsText={configJSON.noCustomerFound}
          value={customerPhoneNumber}
          options={customerList.map((item: { option: string }) => item.option)}
          onChange={(_event, value) =>
            !value && this.handleOnChangeCustomerPhone()
          }
          onInputChange={(_event, value: string) =>
            this.handleCustomerPhoneNumber(value)
          }
          renderOption={(option) => {
            return (
              <StyledOption component={"li"}>
                <FormControlLabel
                  control={<Radio checked={customerPhoneNumber == option} />}
                  label={<StyleLable>{option as string}</StyleLable>}
                />
              </StyledOption>
            );
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='outlined'
              placeholder={configJSON.mobileNumber}
              InputProps={{
                ...params.InputProps,
                type: "search",
              }}
            />
          )}
        />
      </Box>
    );
  }

  renderAddCustomerModel() {
    const { openAddCustomerModal, customerData, isEditCustomerForm } =
      this.state;

    return (
      <AddCustomerModal
        id={"AddCustomerModal"}
        data-test-id='add-customer-modal'
        navigation={this.props.navigation}
        open={openAddCustomerModal}
        handleClose={() => this.handleCloseAddCustomerPopup()}
        handleGetCustomerPhone={(phone: string) =>
          this.onSetCustomerFromPopup(phone as unknown)
        }
        customerData={customerData}
        isEdit={isEditCustomerForm}
        priceId={"35"}
      />
    );
  }

  returnErrorSnackBar() {
    const { errorSnackbarOpen, errorMessage } = this.state;
    return (
      <CustomSnackbar
        data-test-id='custom-errror-snackbar'
        open={errorSnackbarOpen}
        onClose={() => this.handleSnackbarClose()}
        errorMessage={errorMessage}
        autoHideDuration={3000}
        severity='warning'
        hideIcon={true}
      />
    );
  }

  returnCustomerViewPopup() {
    const { viewCustomerPopup, customerData } = this.state;
    return (
      <ShowCustomerDetailsModal
        data-test-id='view-customer-modal'
        navigation={this.props.navigation}
        id={"ShowCustomerDetailsModal"}
        open={viewCustomerPopup}
        handleClose={() => this.handleCloseViewCustomerPopup()}
        customerData={customerData}
        handleIsEdit={() => this.handleEditCustomerForm()}
      />
    );
  }

  renderPickupDatePicker() {
    return (
      <Box className='mt-12'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <InputLabel className='InputLabel'>Pick up</InputLabel>
          <StyledDatePicker
            autoOk
            disableToolbar
            variant='inline'
            format='DD/MM/yyyy'
            placeholder={"Select Pick up Date"}
            value={""}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            keyboardIcon={<img src={IconCalender} width={24} />}
            onChange={() => {}}
          />
        </MuiPickersUtilsProvider>
      </Box>
    );
  }

  renderDeliveryDatePicker() {
    return (
      <Box className='mt-12'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <InputLabel className='InputLabel'>Delivery</InputLabel>
          <StyledDatePicker
            autoOk
            disableToolbar
            variant='inline'
            format='DD/MM/yyyy'
            placeholder={"Select Delivery Date"}
            value={""}
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
            keyboardIcon={<img src={IconCalender} width={24} />}
            onChange={() => {}}
          />
        </MuiPickersUtilsProvider>
      </Box>
    );
  }

  renderPickupandDeliverDatePicker() {
    return (
      <Box className='mt-12 grid-date-picker'>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box className=''>
            <InputLabel className='InputLabel'>Pick up</InputLabel>
            <StyledDatePicker
              autoOk
              disableToolbar
              variant='inline'
              format='DD/MM/yyyy'
              placeholder={"Pick up date"}
              value={""}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<img src={IconCalender} width={24} />}
              onChange={() => {}}
            />
          </Box>
          <Box>
            <InputLabel className='InputLabel'>Delivery</InputLabel>
            <StyledDatePicker
              autoOk
              disableToolbar
              variant='inline'
              format='DD/MM/yyyy'
              placeholder={"Delivery date"}
              value={""}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              keyboardIcon={<img src={IconCalender} width={24} />}
              onChange={() => {}}
            />
          </Box>
        </MuiPickersUtilsProvider>
      </Box>
    );
  }

  getPaymentModal() {
    const {
      order,
      customerData,
      isQuickDropOrder,
      isQuickDropCount,
      paymentClicked,
      paymentModalVisible,
    } = this.state;

    const isStoreOrder = this.props.selectedMainTab == ORDER_TYPES.STORE_ORDER;
    let totalItems = 0;

    if (isQuickDropOrder) {
      totalItems = isQuickDropCount;
    } else {
      totalItems = order?.no_of_items || 0;
    }

    return (
      <PaymentModal
        totalItems={totalItems}
        isMainPayment={true}
        open={paymentModalVisible}
        data-test-id='paymentmodal'
        cleaningOrder={!isStoreOrder}
        paymentClicked={paymentClicked}
        customerId={this.state.editId ? this.state.customerId : String(customerData?.id)}
        onPaymentDone={this.handlePaymentDone}
        handleClose={this.handlePaymentModalClose}
        taxPercentage={this.formatTaxPercentage()}
        order={order as unknown as IOrder}
        onPromoCodeApply={() => this.getLoundryOrder()}
        fromUnpaid = {this.state.editId ? true : false}
      />
    );
  };

  getSpecifications = (orderItemPreference: OrderItemPreferences[]) => {
    const result: {[key: string]: Array<string>} = {};

    orderItemPreference.forEach(item => {
      if(item.attributes.specifications) {
        Object.entries(item.attributes.specifications).forEach(([objKey, objVal]) => {
          if (!result[objKey]) {
            result[objKey] = [];
          }
          if (!result[objKey].includes(objVal)) {
            result[objKey].push(objVal);
          }
        });
      }
    });

    return result;
  };

  getUpcharges = (orderItemPreference: OrderItemPreferences[]) => {
    const output = orderItemPreference
      .flatMap((item) => item.attributes.upcharges)
      .reduce((accUpcharge: IChargeListItem[], current) => {
        if (
          !accUpcharge.some(
            (item) => item.attributes.id === current.attributes.id
          )
        ) {
          accUpcharge.push(current);
        }
        return accUpcharge;
      }, []);

    return output;
  };

  showPreferencesData = (
    orderItemPreference: OrderItemPreferences[],
    preference_id: number
  ) => {
    const uniqueNames = Array.from(
      orderItemPreference
        .reduce((mapVal, objVal) => {
          if(objVal.attributes.preference !== null) {
            if (
              !mapVal.has(
                objVal.attributes.preference.attributes.preference_first_name
              )
            ) {
              mapVal.set(
                objVal.attributes.preference.attributes.preference_first_name,
                objVal
              );
            }
          }
          return mapVal;
        }, new Map())
        .values()
    );

    const filterEditedPreferences = uniqueNames.filter(
      (item: OrderItemPreferences) =>
        item.attributes.preference.attributes.id !== preference_id
    );

    const allPreferencesName = filterEditedPreferences.map(
      (item: OrderItemPreferences) =>
        item.attributes.preference.attributes.preference_first_name
    );

    return (
      <div className='specification-data-show'>
        <span>{allPreferencesName.join(" / ")}</span>
      </div>
    );
  };

  showAllUpchargesInCart = (orderItemPreference: OrderItemPreferences[]) => {
    const upcharges_list = this.getUpcharges(orderItemPreference);

    return (
      <ShowAllUpcharges>
        {upcharges_list.map((item) => {
          return (
            <div className='d-flex'>
              <div className='d-flex'>
                <span>{item.attributes.name}</span>
                {item.attributes.name_translation && (
                  <>
                    <span className='circle' />
                    <span className='mr-6'>{item.attributes.name_translation}</span>
                  </>
                )}
              </div>
              <span> : SAR {item.attributes.order_price}</span>
            </div>
          );
        })}
      </ShowAllUpcharges>
    );
  };

  showAllSpecifications = (orderItemPreference: OrderItemPreferences[]) => {
    const results = this.getSpecifications(orderItemPreference);

    const title = (
      <>
        <ShowPreferenceWrapper className='specification-data-show'>
          {Object.keys(results).map((objVal) => {
            return (
              <React.Fragment key={objVal}>
                <span className='slash-span'>/</span>
                <span className='specification-span-tag'>
                  {`${results[objVal].join(" / ")}`}
                </span>
              </React.Fragment>
            );
          })}
        </ShowPreferenceWrapper>
      </>
    );

    const noData = (
      <ShowPreferenceWrapper className='specification-data-show'>
        <span className='specification-span-tag'>
          No Specification Selected
        </span>
      </ShowPreferenceWrapper>
    );

    const condition = Object.keys(results).length !== 0;

    return (
      <PreferenceTooltip
        title={condition ? title : noData}
        arrow
        placement='top-end'
      >
        <span
          style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        >
          <span
            style={{
              fontSize: "13px",
              fontFamily: "Montserrat",
              textDecoration: "underline",
            }}
          >
            See all preferences
          </span>
          <HelpOutlined
            style={{
              fontSize: "13px",
              fontFamily: "Montserrat",
              margin: "0 0 0 6px",
            }}
          />
        </span>
      </PreferenceTooltip>
    );
  };

  showSQMData(height: string, width: string, weight: string) {
    return (
      <>
      { Number(height) !== 0 && Number(width) !== 0 &&
        <div className='upcharge d-flex'>
          <div>length * width: </div>
          <div>{height} m * {width} m</div>
        </div>
      }
      { Number(weight) !== 0 &&
        <div className='upcharge'>
         weight : {weight} Kg
        </div>
      }
      </>
    );
  }

  shoppingCartLoader() {
    return (
      <Box
        style={{
          position: "absolute",
          top: "-16px",
          left: "-16px",
          right: "-16px",
          bottom: "-16px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1000,
        }}
      >
        <CircularProgress style={{ width: 26, height: 26, color: "#204B9C"}} />
      </Box>
    );
  }

  renderedCartData() {
    const { order, isShoppingcartLoading } = this.state;
    const combinationList = this.getCombinationList();
    const uniqueCatalogueList = this.getUniqueCombinationList(combinationList);

    if (order !== undefined) {
      if (!order.is_quick_drop && order.order_items.length > 0) {
        return (
          <Box style={{ position: "relative" }}>
            {isShoppingcartLoading && this.shoppingCartLoader()}
            <Box className='cart-center-top'>
              <p>Order Details</p>
              <div
                data-test-id={"navigate-to-edit-btn"}
                className='edit-preference-btn'
                onClick = {() => this.handleSendAction("NAVIGATE_EDIT_PREFERENCE")}
              >
                Edit Preference
              </div>
            </Box>
            <Box className='text-right'>
              <Box
                className='clearAllBtn'
                data-test-id='clear-all-btn'
                onClick={() => this.handleSendAction("CLEAR_CART")}
              >
                {configJSON.clearAllText}
              </Box>
            </Box>
            <Box className='scrollable-box'>
              {uniqueCatalogueList.map((uniqueItem: UniqueCombination) => {
                const item = this.findItemFromUnique(uniqueItem);
                const subItems = this.findSubItemsList(uniqueItem);
                return (
                  <div className='product-total-items'>
                    <div className='name'>
                      {`${item?.attributes.category_name} - ${item?.attributes.service?.attributes.name}`}
                    </div>
                    {subItems?.map((subItem) => {
                      return (
                        <div className='product-sub-items'>
                          <div className='item-grid'>
                            <div className='flex-1'>
                              <div className='item-name'>
                                <div className={"d-flex mr-6"}>
                                  <p>
                                    {subItem.attributes.catalogue.product_name}
                                  </p>
                                </div>
                                <div className='d-flex'>
                                  <p>{`(${subItem?.attributes?.service?.attributes?.short_name})`}</p>
                                  <span className='mx-6'>X</span>
                                  <p>{subItem.attributes.quantity}</p>
                                </div>
                              </div>
                              {this.showPreferencesData(
                                subItem.attributes.order_item_preferences,
                                subItem.attributes.preference?.attributes.id
                              )}
                              {this.showSQMData(
                                subItem.attributes.height,
                                subItem.attributes.width,
                                subItem.attributes.weight
                              )}
                              {this.showAllUpchargesInCart(
                                subItem.attributes.order_item_preferences
                              )}
                              {this.showAllSpecifications(
                                subItem.attributes.order_item_preferences
                              )}
                            </div>
                            <div>
                              <div className='total-wrapper'>
                                <div className='total top-space'>
                                  SAR {subItem.attributes.total_price}
                                </div>
                                <IconButton className='delete top-space'>
                                  <img
                                    src={DeleteProductIcon}
                                    className='deleteIcon'
                                    data-test-id={`cart-catalogue-delete-${subItem.attributes.catalogue_id}`}
                                    onClick={() =>
                                      this.handleSendAction(
                                        "DELETE_CART_PRODUCT",
                                        subItem
                                      )
                                    }
                                  />
                                </IconButton>
                              </div>
                              <div className='pcs'>
                                <IconButton
                                  data-test-id={`cart-catalogue-decrease-${subItem.attributes.catalogue_id}`}
                                  onClick={() => this.cartQtyHandler(subItem, "MINUS")}
                                >
                                  <RemoveIcon />
                                </IconButton>
                                <input
                                  className="qty-input"
                                  data-test-id={`qty-input-${subItem.id}`}
                                  value={subItem.attributes.quantity}
                                  onChange={(event) => this.handleInputQty(event, subItem)}
                                />
                                <IconButton
                                  data-test-id={`cart-catalogue-increase-${subItem.attributes.catalogue_id}`}
                                  onClick={() => this.cartQtyHandler(subItem, "PLUS")}
                                >
                                  <AddIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Box>
          </Box>
        );
      } else {
        return (
          <Box className='empty-cart-wrapper'>
            {isShoppingcartLoading && this.shoppingCartLoader()}
            <img src={cartEmptyImage} alt='empty-cart-image' />
            <p>{configJSON.Strings.cart_empty_text}</p>
          </Box>
        );
      }
    } else {
      return (
        <Box className='empty-cart-wrapper'>
          <img src={cartEmptyImage} alt='empty-cart-image' />
          <p>{configJSON.Strings.cart_empty_text}</p>
        </Box>
      );
    }
  }

  renderSubtotalCollapse() {
    const { cartTotalOpen, order } = this.state;

    return (
      <div
        data-test-id='order-summary-total'
        className='order-summary-total'
        onClick={() => this.handleToggleCartTotal()}
      >
        <Collapse in={cartTotalOpen} timeout='auto' unmountOnExit>
          <div className='total-item'>
            <div>Total Items</div>
            <div>{order?.no_of_items}</div>
          </div>
          <div className='total-item'>
            <div>Pcs</div>
            <div>{order?.no_of_pieces}</div>
          </div>
          <div className='total-item'>
            <div>Subtotal</div>
            <div>SAR {order?.sub_total}</div>
          </div>
          {order && Number(order?.applied_discount) > 0 &&
          <div className='total-item'>
            <div>Promo Code Discount</div>
            <div>- SAR {order.applied_discount}</div>
          </div>
          }
          <div className='total-item'>
            <div>Tax ({order?.tax_percentage})</div>
            <div>SAR {order?.total_tax}</div>
          </div>
          <div>
            <hr />
          </div>
        </Collapse>
        <div className='total-item total' data-test-id='btn-total'>
          <div>Total</div>
          <div className='flex total' style={{ gap: 8, paddingBottom: 0 }}>
            <div>SAR {order?.total}</div>
            <div className='caret-icon'>
              {cartTotalOpen ? <ExpandMore /> : <ExpandLess />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOrderSummary() {
    const { placedOrder, isQuickDropOrder, customerData } = this.state;
    return (
      <OrderSummary
        data-test-id='order-summary'
        order={placedOrder as unknown as IOrder}
        customer={customerData as unknown as ICustomer}
        onNewOrder={() => this.handleOrderSummaryNewOrder()}
        onClose={() => this.handleClearOrder()}
        isQuickDrop={isQuickDropOrder}
        onPrint={() => this.handlePrintReceipt(placedOrder?.invoice_receipt_pdf_url)}
      />
    );
  }

  renderNotesAndSaveForFuture() {
    const { order } = this.state;
    return (
      <>
      { Number(order?.order_items?.length) > 0 && 
      <Box className='notes-text-wrap'>
        <StyledTextArea
          data-test-id='orde-notes-textarea'
          minRows={1}
          placeholder='Add Note'
          value={this.state.notesTextArea}
          onChange={(event: React.ChangeEvent<{ value: string }>) =>
            this.handleOnChangeNotes(event)
          }
        />
        <Box className='toggle-switch-wrap'>
          <label>Save</label>
          <StyledCustomSwitch
            data-test-id='field-status-switch'
            checked={this.state.saveForFuture}
            onChange={(checked) => this.handleOnCheckedToggle(checked)}
          />
        </Box>
      </Box>
      }
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const {
      openAddCustomerModal,
      errorSnackbarOpen,
      viewCustomerPopup,
      serviceDropdownList,
      selectedServiceValue,
      orderSummaryVisible,
      paymentModalVisible
    } = this.state;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <CustomLoader loading={this.state.isLoading} />
        <CartContainer>
          <CartTopSearch>
            <Box className='button-container'>
              {this.renderCustomerSearch()}
              <Button className='customerButton'>
                <img
                  src={customerAddIcon}
                  className='cursor-pointer'
                  alt='customer add icon'
                  data-test-id='btn-add-customer'
                  onClick={() => this.handleOpenAddCustomerPopup()}
                />
              </Button>
              <Button className='customerButton'>
                <img
                  src={customerViewIcon}
                  className='cursor-pointer'
                  alt='customer view icon'
                  data-test-id='btn-view-customer'
                  onClick={() => this.handleViewCustomerPopupOpen()}
                />
              </Button>
            </Box>
          </CartTopSearch>
          <CartCenterBox>{this.renderedCartData()}</CartCenterBox>
          <CartServicePickup style={{ display: "none" }}>
            <Box>
              <InputLabel className='InputLabel'>
                {configJSON.Strings.service}
              </InputLabel>
              <CustomFormControl variant='outlined' className='formControl'>
                <SelectionBoxUpdate
                  className='service-dropdown'
                  data-test-id='service-dropdown'
                  labelId={"serviceDropdown"}
                  id={"service-dropdown"}
                  name={"service-dropdown"}
                  fullWidth
                  displayEmpty
                  defaultValue={""}
                  inputProps={{ disableUnderline: true }}
                  MenuProps={MenuProps}
                  value={selectedServiceValue}
                  onChange={(
                    event: React.ChangeEvent<{
                      value: string;
                    }>
                  ) => this.handleServiceDropdown(event)}
                >
                  <MenuItem
                    style={{ color: colors().darkliver, display: "none" }}
                    value=''
                  >
                    {configJSON.Strings.select_service}
                  </MenuItem>
                  {serviceDropdownList.map((option) => {
                    return (
                      <CustomMenuItem value={option.value}>
                        <FormControlLabel
                          checked={selectedServiceValue === option.value}
                          control={<StyledRadio />}
                          label={""}
                          name='option'
                        />
                        {option.option}
                      </CustomMenuItem>
                    );
                  })}
                </SelectionBoxUpdate>
              </CustomFormControl>
            </Box>
            {selectedServiceValue == "pick_up" && this.renderPickupDatePicker()}
            {selectedServiceValue == "delivery" &&
              this.renderDeliveryDatePicker()}
            {selectedServiceValue == "pick_up_and_delivery" &&
              this.renderPickupandDeliverDatePicker()}
          </CartServicePickup>
          {!orderSummaryVisible && this.renderNotesAndSaveForFuture()}
          {orderSummaryVisible && this.renderOrderSummary()}
          <CartBottomBox>
            {this.renderSubtotalCollapse()}
            <QuickDrop
              disabled={this.isQuickDropDisabled()}
              count={this.state.isQuickDropCount}
              onCountChange={this.handleQuickDropChange}
              handleChangeQty={(event) => this.handleChangeQuickInputQty(event)}
            />
            <UpdateMainButton
              disabled={this.getPayButtonEnable()}
              data-test-id='btnPay'
              onClick={() => this.handlePayButton()}
              className='fullWidth'
            >
              {this.getPayButtonText()}
            </UpdateMainButton>
          </CartBottomBox>
        </CartContainer>
        {openAddCustomerModal && this.renderAddCustomerModel()}
        {viewCustomerPopup && this.returnCustomerViewPopup()}
        {errorSnackbarOpen && this.returnErrorSnackBar()}
        {paymentModalVisible && this.getPaymentModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CartContainer = styled(Box)({
  maxWidth: "400px",
  width: "100%",
  padding: "12px",
  border: `1px solid ${colors().lightborder}`,
  borderRadius: "8px",
  "& .notes-text-wrap": {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    margin: "12px 0 0 0",
  },
  "& .toggle-switch-wrap": {
    display: "flex",
    gap: "8px",
  },
  "@media only screen and (max-width: 1280px)": {
    maxWidth: "360px",
  },
  "@media only screen and (max-width: 1024px)": {
    maxWidth: "320px",
  },
});

export const MainButton = styled(Button)({
  background: "#204B9C",
  fontWeight: 700,
  fontSize: "16px",
  lineHeight: "24px",
  color: colors().white,
  height: "50px",
  width: "186px",
  borderRadius: "8px",
  marginRight: 32,
  textTransform: "unset" as const,
  "&.MuiButton-root.Mui-disabled": {
    color: colors().white,
    cursor: "not-allowed",
    opacity: 0.5,
  },
  "&:hover": {
    background: "#204B9C",
    color: colors().white,
  },
  "&.buttonSecondary": {
    backgroundColor: "#F1F5F9",
    color: "#64748B",
  },
  "&.fullWidth": {
    width: "100%",
  },
  "&.mt-24": {
    marginTop: 24,
  },
});

const UpdateMainButton = styled(MainButton)({
  marginTop: "12px",
  height: "44px",
});

const CartTopSearch = styled(Box)({
  display: "flex",
  gap: "6px",
  "& .MuiOutlinedInput-root": {
    width: "100%",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: colors().lightborder,
  },
  "& .countryInputDigit": {
    width: "72px",
    "& .MuiOutlinedInput-input": {
      padding: "9px 8px",
    },
    "& input": {
      fontWeight: 500,
    },
    "& .MuiOutlinedInput-root": {
      border: "none",
    },
    "@media only screen and (max-width: 1280px)": {
      width: "64px",
    },
    "@media only screen and (max-width: 1024px)": {
      width: "50px",
    },
  },
  "& .MuiInputBase-input": {
    height: "unset",
  },
  "& .MuiAutocomplete-root, & .MuiTextField-root": {
    paddingTop: 0,
  },
  "& .MuiAutocomplete-root .MuiOutlinedInput-root": {
    minWidth: 175,
    padding: 0,
    paddingRight: 30,
    "& input": {
      fontWeight: 500,
      padding: "9px 8px !important",
    },
  },
  "& .search-customer-wrap": {
    display: "flex",
    gap: "6px",
    width: "100%",
  },
  "& .cursor-pointer": { cursor: "pointer" },
  "& .customerButton": {
    padding: 0,
    backgroundColor: "#204B9C",
    borderRadius: "8px",
    height:"32px !important",
    minWidth:"32px !important",
    "@media only screen and (max-width: 1280px)": {
      height:"25px !important",
      minWidth:"25px !important",
      "& > span > img": {
        width: 16
      }
    },
    "@media only screen and (max-width: 1024px)": {
      height:"20px !important",
      minWidth:"20px !important",
      "& > span > img": {
        width: 16
      }
    },
  },
  "& .button-container": {
    display: "flex",
    alignItems: "center",
    gap: "6px",
    width: "100%",
  },
});

const SelectionBoxUpdate = styled(MultiSelectionBox)({
  "&.service-dropdown": {
    height: "44px !important",
  },
  "& .MuiSelect-root": {
    height: "44px !important",
    minHeight: "44px !important",
  },
});

const CartCenterBox = styled(Box)({
  padding: "16px",
  margin: "12px 0",
  borderRadius: "12px",
  boxShadow: "0px 2px 8px 0px #00000014",
  "& .cart-center-top": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  "& .empty-cart-wrapper": {
    position: "relative",
    padding: "24px 0",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      fontSize: "16px",
      fontWeight: 500,
      color: colors().silverChalice,
    },
  },
  "& .scrollable-box": {
    maxHeight: "344px",
    overflow: "auto",
  },
  "& .edit-preference-btn": {
    padding: "8px 10px",
    borderRadius: "8px",
    background: "rgba(32, 75, 156, 0.10)",
    color: "#204B9C",
    cursor: "pointer",
    fontSize: "13px",
    fontWeight: 600,
  },
  "& .text-right": {
    textAlign: "right",
  },
  "& .clearAllBtn": {
    color: colors().statusRed,
    borderBottom: `1px solid ${colors().statusRed}`,
    display: "inline-block",
    cursor: "pointer",
    marginTop: "8px 0 !important",
    fontWeight: 500,
    fontSize: "16px",
  },
  "& .product-total-items": {
    fontSize: 14,
  },
  "& .product-sub-items": {
    gap: 8,
    display: "flex",
    flexFlow: "column",
    padding: "12px 0",
    borderBottom: `1px solid ${colors().lightborder}`,
  },
  "& .mt-10": {
    margin: "10px 0",
  },
  "& .total": {
    fontWeight: 500,
    color: colors().viewTextColor,
  },
  "& .pcs": {
    gap: 4,
    display: "flex",
    borderRadius: 8,
    padding: "5px 12px",
    alignItems: "center",
    color: colors().white,
    justifyContent: "center",
    background: colors().orange,
  },
  "& .qty-input": {
    width: "35px",
    background: "transparent",
    outline: "none",
    border: "1px solid transparent",
    textAlign: "center",
    color: colors().white,
  },
  "& .qty-input:focus": {
    border: "1px solid white",
  },
  "& .total-wrapper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 0 12px 0",
  },
  "& .item-grid": {
    gap: 8,
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-start",
    "& .grow": {
      flexGrow: 1,
    },
    "& .delete": {
      margin: "0 0 0 8px !important",
      border: "none",
      cursor: "pointer",
      backgroundColor: colors().white,
    },
    "& .MuiIconButton-root": {
      margin: 0,
      padding: 0,
      color: colors().white,
    },
    "@media only screen and (max-width: 1024px)": {
      flexDirection: "column"
    },
  },
  "& .upcharge": {
    fontSize: "13px",
    fontWeight: 400,
    color: colors().darkjunglegreen,
    textTransform: "capitalize"
  },
  "& .name": {
    fontWeight: 600,
    color: colors().cyancobaltblue,
    fontSize: "14px",
    lineHeight: "18px",
    marginTop: "8px",
  },
  "& .item-name": {
    display: "flex",
    flexWrap: "wrap",
    flexGrow: 1,
    fontWeight: 500,
    color: colors().darkjunglegreen,
    "& .circle": {
      height: "3px",
      width: "3px",
      background: colors().black,
      borderRadius: "50%",
      margin: "0 6px",
    },
    "& .mx-6": {
      margin: "0 6px",
    },
    "& .mr-6": {
      margin: "0 6px 0 0",
    },
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
  },
  "& .upcharge-name": {
    fontSize: "12px",
  },
  "& .flex-1": {
    flex: "1 1 0",
  },
  "& .specification-data-show": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .specification-span-tag": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen,
    },
    "& .slash-span": {
      margin: "0 4px",
    },
    "& .slash-span:first-child": {
      display: "none"
    }
  },
});

const CartServicePickup = styled(Box)({
  "& .mt-12": {
    margin: "12px 0 0 0",
  },
  "& .InputLabel": {
    fontFamily: "Montserrat",
    fontSize: "16px",
    lineHeight: "20px",
    fontWeight: 600,
    color: colors().charcoal,
    margin: "0 0 4px 0",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "13px",
    },
  },
  "& .formControl": {
    width: "100%",
    border: `1px solid ${colors().lightborder}`,
    borderRadius: "8px",
  },
  "& .grid-date-picker": {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: "16px",
  },
});

const StyledDatePicker = styled(KeyboardDatePicker)({
  width: "100%",
  "& > div": {
    borderRadius: 8,
    border: `1px solid ${colors().lightborder}`,
    boxShadow: `0px 0px 1px ${colors().lightborder}`,
  },
  "& .MuiButtonBase-root": {
    padding: "4px",
  },
  "& input, & .MuiInputBase-input::placeholder": {
    opacity: 1,
    fontSize: 14,
    color: colors().darkliver,
    padding: "12px",
    fontFamily: "Montserrat",
    "@media only screen and (max-width: 1024px)": {
      fontSize: "12px",
      padding: "15px 5px",
    },
  },
  "& .MuiInput-underline::after, & .MuiInput-underline::before, & p": {
    display: "none",
  },
  "@media only screen and (max-width: 1024px)": {
    "& .MuiInputBase-input": {
      fontSize: "12px",
      padding: "15px 5px",
    },
    "& div button": {
      padding: "2px 1px",
    },
  },
});

const StyledAutoComplete = styled(Autocomplete)({
  flexGrow: 1,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    fontSize: 14,
  },
  "& .MuiInputBase-input": {
    fontSize: 14,
    fontFamily: "Montserrat",
  },
});

const StyledOption = styled(Box)({
  "& > img": {
    mr: 2,
    flexShrink: 0,
  },
});

const StyleLable = styled("p")({
  fontSize: "1rem",
  fontFamily: "Montserrat",
  fontWeight: 400,
  "@media only screen and (max-width: 1024px)": {
    fontSize: 13,
  },
});

const CartBottomBox = styled(Box)({
  "& .order-summary-total": {
    fontSize: 16,
    marginTop: 8,
    borderRadius: 8,
    padding: "8px 12px",
    color: colors().white,
    backgroundColor: colors().orange,
  },
  "& .total-item": {
    fontSize: 14,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    paddingBottom: 8,
    justifyContent: "space-between",
  },
  "& .total-item:last-child": {
    paddingBottom: 0,
  },
  "& hr": {
    marginTop: 8,
    border: "none",
    marginBottom: 16,
    borderBottom: `1px solid ${colors().white}`,
  },
  "& .total": {
    fontSize: "16px",
  },
  "& .flex": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledTextArea = styled(TextareaAutosize)({
  padding: 8,
  width: "100%",
  borderRadius: 8,
  border: `1px solid ${colors().lightborder}`,
  fontSize: "14px",
});

const StyledCustomSwitch = styled(CustomSwitch)({
  width: 36,
  height: 20,
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
    backgroundColor: "white",
  },
  "& .Mui-checked": {
    transform: "translateX(16px) !important",
  },
});

const ShowPreferenceWrapper = styled("div")({
  "&.specification-data-show": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    "& .specification-span-tag": {
      fontSize: "12px",
      fontFamily: "Montserrat",
      color: colors().darkjunglegreen,
      textTransform: "capitalize"
    },
    "& .slash-span": {
      margin: "0 4px",
    },
    "& .slash-span:first-child": {
      display: "none"
    }
  },
});

const ShowAllUpcharges = styled("div")({
  "& span": {
    fontSize: "13px",
    fontWeight: 400,
    color: colors().darkjunglegreen,
    textTransform: "capitalize"
  },
  "& .d-flex": {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  "& .circle": {
    height: "3px",
    width: "3px",
    background: colors().darkjunglegreen,
    borderRadius: "50%",
    margin: "0 6px",
  },
  "& .mr-6": {
    margin: "0 6px 0 0"
  }
});

export const PreferenceTooltip = withStyles({
  tooltip: {
    color: "black",
    fontSize: "12px",
    backgroundColor: "white",
    width: "320px",
    maxWidth: "320px",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
  arrow: {
    color: "white",
    boxShadow:
      "rgba(0, 0, 0, 0.3) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px",
  },
})(Tooltip);
// Customizable Area End
