import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import {
  ICustomer,
  IOrder,
  IPaymentOption,
} from "../../OrderCreation/src/OrderCreationController.web";
import { apiCall } from "../../../components/src/common";

export const configJSON = require("./config.js");

export interface Transaction {
  id: string;
  attributes: {
    id: number;
    account_id: number;
    order_management_order_id: number;
    amount: string;
    payment_method: {
      id: number;
    };
  };
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  order?: IOrder;
  customer?: ICustomer;
  onNewOrder?: () => void;
  onPrint?: () => void;
  onPrintTag?: () => void;
  onClose?: () => void;
  isQuickDrop?: boolean;
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  printModalOpen: boolean;
  infoView: boolean;
  paymentOptions: IPaymentOption[];
  // Customizable Area End
}

export interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OrderSummaryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPrintTagsCallId: string = "";
  getPaymentMethodsListApiId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      printModalOpen: false,
      infoView: false,
      paymentOptions: []
      // Customizable Area End
    };
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    const successResponseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if(apiRequestId === this.getPaymentMethodsListApiId) {
      this.setState({ paymentOptions: successResponseJson.data });
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onPrintModalClose = () => {
    this.setState({ printModalOpen: false, infoView: false });
  };

  onPrintModalOpen = (infoView: boolean = false) => {
    this.setState({ printModalOpen: true, infoView });
  };

  getPaymentMethodsList = () => {
    const headers = {
      "Content-Type": configJSON.applicationJsonContentType,
      token: localStorage.getItem("token"),
    };

    const getPayment = apiCall({
      httpBody: {},
      header: headers,
      url: configJSON.getPaymentMethodsListApi,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getPaymentMethodsListApiId = getPayment.messageId;
    runEngine.sendMessage(getPayment.id, getPayment);
  };

  handleGetPaymentMethods = () => {
    let data = localStorage.getItem("payment_method_lists");

    if (data) {
      this.setState({ paymentOptions: JSON.parse(data) })
    } else {
      this.getPaymentMethodsList();
    };
  };

  async componentDidMount() {
    super.componentDidMount();
    this.handleGetPaymentMethods();
  }
  // Customizable Area End
}
