import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import { getStorageData, setStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { ReceiptPrinterSetting } from "../../navigationmenu/src/PageContainerController.web";
import { navigateTo, CustomEnums, getCustomEnumName, HardwarePermissionStatus, customPermissionApiKey, checkForHardwarePermissonStatus } from "../../utilities/src/CustomBlockHelpers";
import { makeApiMessage } from "../../../components/src/common";
import { cloneDeep, flatten, toString } from "lodash";
import qzTray, { PrintData } from "qz-tray";
import { KEYUTIL, KJUR, stob64, hextorstr } from "jsrsasign";
import { FormikProps } from "formik";
import FileUpdater from "../../../components/src/customComponents/FileUpdater";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { IUserContext } from "../../navigationmenu/src/PageContainerController.web";
export const globalHardwareSetting = {
  qzTrayPrinter: "",
  garmentTagQzTrayPrinter: "",
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  snackbarMode?: boolean;
  isGarmentTagSetting?: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStation: string;
  printers: string[];
  stations: string[];
  snackbarMessage: string;
  initialFormValue: ReceiptPrinterSetting;
  permissionStatus: HardwarePermissionStatus | null
  loading: boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrintController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getSigningDataCallId = "";
  getOrderDetailCallId = "";
  verifyOutFilesCallId = "";
  updateMissingOutFilesCallId = "";
  getGarmentTagSettingCallId = "";
  updateSettingCallId = "";
  shouldScanFile = Boolean(this.props.snackbarMode);
  pathsToScanFiles: string[] = [];
  verifyList: {fileName: string; filePath: string}[] =[]
  isSigned = false;

  imageInputRef = React.createRef<HTMLInputElement>()
  pdfInputRef = React.createRef<HTMLInputElement>()
  formRef = React.createRef<FormikProps<ReceiptPrinterSetting>>()
  fileUpdater: FileUpdater = FileUpdater.getInstance();
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getCustomEnumName(CustomEnums.CustomActionReducers)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedStation: "",
      printers: [],
      stations: Array(5).fill(0).map((element, index) => `Station ${index + 1}`),
      snackbarMessage: "",
      initialFormValue: {
        id: -1,
        account_id: -1,
        printer_name: null,
        printer_type: null,
        station: null,
        auto_print_receipt: false,
        auto_print_pay_on_pickup: false,
        receipt_print_new_order: false,
        receipt_print_clean_page: false,
        receipt_print_ready_page: false,
        receipt_print_delivered_page: false,
        no_of_copies: 1,
        printer_setting_type: "receipt_printer",
      },
      permissionStatus: null,
      loading: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id && !this.props.snackbarMode) {
      const receivedData = message.getData(getName(MessageEnum.SessionResponseData))
      if (receivedData?.printers) {
        this.setState({ printers: receivedData.printers })
      }
    }

    if (this.props.snackbarMode) {
      this.receiveActions(message)
      this.receiveApiResponse(message)
    } else {
      this.receiveSettingsResponse(message)
    }


    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    super.componentDidMount()

    const [qzTrayPrinter, garmentTagQzTrayPrinter, sharedFolderLocation] = await Promise.all([
      configJSON.receiptPrinterStorageKey,
      configJSON.garmentTagStorageKey,
      configJSON.sharedFolderLocationStorageKey
    ]
      .map(async storageKey => {
        const storedData = await getStorageData(storageKey)
        return toString(storedData)
      }
      ));
    Object.assign(globalHardwareSetting, { qzTrayPrinter, garmentTagQzTrayPrinter, sharedFolderLocation })
    this.setState((prev) => ({ initialFormValue: {...prev.initialFormValue, printer_name: this.props.isGarmentTagSetting ? garmentTagQzTrayPrinter : qzTrayPrinter }}), () => {
      if (this.props.snackbarMode) {
        this.fetchCert()
      }
      else {
        this.requestSession()
      }
    });
    if (this.props.isGarmentTagSetting) this.getGarmentTagSetting()
  }
  async componentWillUnmount(): Promise<void> {
    super.componentWillUnmount()
    if (qzTray.websocket.isActive() && this.props.snackbarMode) {
      await qzTray.file.stopListening()
      qzTray.websocket.disconnect()
    }
  }

  fetchCert = async () => {
    const requestMessage = makeApiMessage({
      url: configJSON.getQzTrayCertEndPoint,
      method: configJSON.httpGetMethod,
    });
    this.getSigningDataCallId = requestMessage.messageId;
    this.send(requestMessage)
  }

  setupQZTray = async (certificate?: string, privateKeyInput?: string) => {
    const message = new Message(getName(MessageEnum.SessionSaveMessage))
    try {
      if (certificate && privateKeyInput) {
        qzTray.security.setCertificatePromise((resolve: Function) => {
          resolve(certificate)
        })
        qzTray.security.setSignatureAlgorithm("SHA512");
        qzTray.security.setSignaturePromise((toSign: string) => (resolve: Function, reject: Function) => {
          try {
            const privateKey = KEYUTIL.getKey(privateKeyInput)
            const signature = new KJUR.crypto.Signature({"alg" : "SHA512withRSA"})
            signature.init(privateKey)
            signature.updateString(toSign)
            const hexStr = signature.sign()
            resolve(stob64(hextorstr(hexStr)))
          } catch (error) {
            console.error(error)
            reject(error)
          }
        })
      } else {
        this.setState({snackbarMessage: "error fetching QZ Tray certificate"})
      }
      if (!qzTray.websocket.isActive()) {
        await qzTray.websocket.connect();
      }
      this.isSigned = Boolean(certificate && privateKeyInput)
      if (this.pathsToScanFiles.length) {
        this.scanFiles(this.pathsToScanFiles)
      }
      const printers = await qzTray.printers.find();
      const printerList = flatten([printers])
      this.setState({
        printers: printerList,
      })
      message.addData(getName(MessageEnum.SessionResponseData), { printers: printerList })
    } catch (error) {
      this.setState({ printers: [] })
      message.addData(getName(MessageEnum.SessionResponseData), { printers: [] })
      console.error(error)
    } finally {
      this.send(message)
      this.requestSession()
    }
  }

  receiveApiResponse = (message: Message) => {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getSigningDataCallId) {
        const { certificate, decrytped_private_key } = responseJson?.qz_tray_key || {}
        this.setupQZTray(certificate, decrytped_private_key)
      }

      if (apiRequestCallId === this.verifyOutFilesCallId && responseJson?.missing_out_files) {
        this.updateMissingOutFiles(responseJson?.missing_out_files || [])
      }

      if (apiRequestCallId === this.updateMissingOutFilesCallId) {
        this.verifyList = []
        this.fileUpdater.updateMetalProgetti()
      }

      this.fileUpdater.receiveApiResponse(apiRequestCallId)
    }
  }

  receiveSettingsResponse = (message: Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if ([this.updateSettingCallId, this.getGarmentTagSettingCallId].includes(apiRequestCallId)) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson?.data) {
            if (this.updateSettingCallId === apiRequestCallId) {
              this.handleSavePrinterSelection();
            } else {
              this.handleGetPrinterSetting(responseJson.data);
            }
          } else {
            this.setState({loading: false, snackbarMessage: "unable to update printer setting"})
          }
        }
      }
  }

  receiveActions = async (message: Message) => {
    if (getCustomEnumName(CustomEnums.CustomActionReducers) === message.id) {
      const action = message.getData(getCustomEnumName(CustomEnums.CustomReducerAction));
      const payload = message.getData(getCustomEnumName(CustomEnums.CustomReducerPayload));
      switch (action) {
        case "PRINT_FILE": {
          const numOfCopiesSetting = message.getData(getCustomEnumName(CustomEnums.NumberOfCopiesToPrint));
          this.printFromFile(payload, numOfCopiesSetting)
          break;
        }
        case "PRINT_TAGS":
          this.printTags(payload)
          break;
        case "WRITE_FILES":
          this.writeFiles(payload)
          break;
        case "SCAN_FILES":
          this.receiveScanAction(payload || [])
          break;
        case "LOG_OUT":
          this.handleLogout()
          break;
        default:
          break;
      }
    }
  }

  handleLogout = async () => {
    await this.fileUpdater.stopListening()
    this.shouldScanFile = true
  }

  receiveScanAction = (paths: string[]) => {
    if (this.isSigned && qzTray.websocket.isActive()) {
      this.scanFiles(paths)
    }
    else {
      this.pathsToScanFiles = paths
    }
  }

  changePrinter = (event: React.ChangeEvent<{ value: unknown }>) => {
    const newSelectedPrinter = toString(event.target.value);
    this.formRef.current?.setFieldValue("printer_name", newSelectedPrinter)
  }

  changeStation = (event: React.ChangeEvent<{ value: unknown }>) => this.setState({ selectedStation: toString(event.target.value) })

  requestSession = () => {
    const message = new Message(getName(MessageEnum.SessionRequestMessage))
    this.send(message)
  }

  scanFiles = async (paths: string[]) => {
    if (!this.shouldScanFile) return;
    try {
      const scanResults = await Promise.all(paths.flatMap(path => this.scanPath(path)))
      const files = scanResults.flat()
      if (files.length) {
        this.verifyOutFiles(files)
      }
      this.fileUpdater.startListening(paths)
      return files
    } catch (error) {
      console.error(error)
      return null
    } finally {
      this.shouldScanFile = false
    }
  }

  scanPath = async (path: string) => {
    try {
      const files = await qzTray.file.list(path, { sandbox: false, shared: true })
      return files.map(file => ({filePath: path + "\\" + file, fileName: file}))
    } catch (error) {
      console.log("ERROR SCANNING FILES AT >>> ", path)
      console.error(error)
      return []
    }
  }

  verifyOutFiles = (files: {fileName: string; filePath: string}[]) => {
    const apiMessage = makeApiMessage({
      url: configJSON.verifyOutFilesEndPoint,
      method: configJSON.httpPostMethod,
      body: JSON.stringify({
        file_names: files.map(file => file.fileName)
      })
    })
    this.verifyOutFilesCallId = apiMessage.messageId
    this.verifyList = files
    this.send(apiMessage)
  }

  updateMissingOutFiles = async (fileNames: string[]) => {
    const allData = await Promise.all(fileNames.map(async fileName => {
      try {
        const filePath = this.verifyList.find(item => item.fileName === fileName)?.filePath
        if (!filePath) return null;
        const file_content = await qzTray.file.read(filePath, { shared: true, sandbox: false })
        return {
          file_name: fileName,
          file_content
        }
      } catch (error) {
        return null
      }
    }))
    const postData = allData.filter(Boolean) as Array<{ file_name: string, file_content: string }>
    if (postData.length) {
      const apiMessage = makeApiMessage({
        url: configJSON.updateOutFiles,
        method: configJSON.httpPostMethod,
        body: JSON.stringify({
          data: postData
        })
      })
      this.updateMissingOutFilesCallId = apiMessage.messageId
      this.send(apiMessage)
    }
  }

  printWithQzTray = async (
    printData: PrintData[][] | PrintData[],
    printerName: string,
  ) => {
    runEngine.debugLog("qztray print data and config", { printData, printerName })
    if (!printData.length) return;
    if (!this.state.printers.includes(printerName)) {
      return this.setState({ snackbarMessage: this.errorMessage() });
    }
    try {
      const config = qzTray.configs.create(printerName)
      await qzTray.print(config, printData as PrintData[])
    } catch (error) {
      this.setState({ snackbarMessage: "Print Error" })
      console.error(error)
    }
  }

  printTags = (rawDatas: Array<string>) => {
    if (!this.state.printers.includes(globalHardwareSetting.garmentTagQzTrayPrinter)) {
      return this.setState({ snackbarMessage: this.errorMessage("Garment Tag Printer") });
    }
    const printData = rawDatas.map(rawData => [
      {
        data: rawData,
        type: "raw",
        format: "command",
        flavor: 'plain',
      }
    ]) as PrintData[][]
    this.printWithQzTray(
      printData,
      globalHardwareSetting.garmentTagQzTrayPrinter
    )
  }

  printFromFile = (payload: {data: string; format: "image" | "pdf"}, numOfCopiesSetting?: null | number) => {
    const numOfCopies = numOfCopiesSetting || 1
    const itemPrintData = {
      type: "pixel",
      flavor: "file",
      ...payload
    }
    const printData = Array(numOfCopies).fill([itemPrintData]) 
    this.printWithQzTray(printData, globalHardwareSetting.qzTrayPrinter);
  }

  writeFiles = async (payload: { file_path: string, file_content: string }[]) => {
    try {
      await Promise.all(payload.map(fileItem => 
        qzTray.file.write(fileItem.file_path, { sandbox: false, shared: true, data: fileItem.file_content }))
      )
      this.setState({ snackbarMessage: configJSON.writeFileSuccessMsg })
    } catch (error) {
      this.setState({ snackbarMessage: configJSON.sharedFolderConnectionError })
      console.error(error)
    }
  }

  handleCloseSnackbar = () => this.setState({ snackbarMessage: "" })

  testPrintFile = (isPrintImage?: boolean) => {
    const data = isPrintImage ? this.imageInputRef.current?.value : this.pdfInputRef.current?.value
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: isPrintImage ? "image" : "pdf",
      data
    })
    this.send(printMessage)
  }

  testPrintRaw = async (inputData: {data: string | Uint8Array | string[]}) => {
    try {
      const storedPrinter = await getStorageData("garmentTagQzTrayPrinter");
      const config = qzTray.configs.create(storedPrinter)
      const printData = [
        {
          type: 'raw',
          format: 'command',
          flavor: 'plain',
          ...inputData
        }
      ]
      await qzTray.print(config, printData as PrintData[])
    }catch(error) {

    }

  }

  handleRedirect = () => this.props.navigation && navigateTo({ id: "", props: this.props, screenName: "AdvancedSearch" })

  handleSave = (formValues: ReceiptPrinterSetting) => {
    const printer_name = this.currentPrinterName() 
    const updateValues: Partial<ReceiptPrinterSetting> = this.props.isGarmentTagSetting
    ? { 
      id: formValues.id,
      account_id: formValues.account_id,
      printer_name
    } 
    : {
      ...formValues,
      printer_name
    }
    this.updatePrinterSetting(updateValues)
  }


  handleSavePrinterSelection = async () => {
    const printerName = this.currentPrinterName()
    const storageKey = this.storageKey() as "qzTrayPrinter" | "garmentTagQzTrayPrinter"
    if (printerName) {
      await setStorageData(storageKey, printerName)
      globalHardwareSetting[storageKey] = printerName
    } else {
      await removeStorageData(storageKey)
      globalHardwareSetting[storageKey] = ""
    }
    this.setState({loading: false, snackbarMessage: configJSON.saveSettingSuccessMsg})
  }

  storageKey = () => this.props.isGarmentTagSetting ? configJSON.garmentTagStorageKey : configJSON.receiptPrinterStorageKey;

  currentPrinterName = () => {
    const printer_name = toString(this.formRef.current?.values.printer_name)
    const printerName = this.state.printers.includes(printer_name) ? printer_name : ""
    return printerName
  }

  handleUserChange = (context: IUserContext) => {
    const apiKey = customPermissionApiKey.hardwarePermission;
    const userData = context.user?.attributes.permission_groups;
    const value = checkForHardwarePermissonStatus(apiKey, userData as unknown as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value
    });
  }
  errorMessage = (printerType?: string) => `Select a printer in ${printerType || configJSON.receiptPrinter} setting page to print`;

  handleGetPrinterSetting = (newPrinterSetting?: ReceiptPrinterSetting) => {
    if (!newPrinterSetting) return this.setState({loading: false, snackbarMessage: "unable to fetch printer setting"})
    const clonedPrinterSetting = cloneDeep(newPrinterSetting)
    delete clonedPrinterSetting.created_at
    delete clonedPrinterSetting.updated_at
    delete clonedPrinterSetting.printer_setting_type
    this.setState((prev) => ({
      initialFormValue: {
        ...clonedPrinterSetting,
        printer_name: prev.initialFormValue.printer_name
      },
      loading: false
    }))
  }

  updatePrinterSetting = (updateValues: Partial<ReceiptPrinterSetting>) => {
    this.setState({loading: true})
    const requestMessage = makeApiMessage({
      method: configJSON.httpPutMethod,
      url: configJSON.printerSettingsApi + updateValues.id,
      body: JSON.stringify({
        data: updateValues
      })
    })
    this.updateSettingCallId = requestMessage.messageId
    this.send(requestMessage)
  }

  getGarmentTagSetting = () => {
    const requestMessage = makeApiMessage({
      method: configJSON.httpGetMethod,
      url: configJSON.garmentTagSettingApi,
    })
    this.getGarmentTagSettingCallId = requestMessage.messageId
    this.send(requestMessage)
  }
  // Customizable Area End
}
