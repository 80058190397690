import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { apiCall } from "../../../components/src/common";
import { ISortingData } from "../../../components/src/SortingTableHeader";
import { IFilter } from "../../../components/src/FilterPopover";
import { IUserContext } from "../../../blocks/navigationmenu/src/PageContainerController.web";
import {
  PermissionStatus,
  checkForNewPermissonStatus,
  customPermissionApiKey,
  navigateTo,
  sortCondition,
  CustomEnums,
  getCustomEnumName
} from "../../../blocks/utilities/src/CustomBlockHelpers";
import { PermissionGroupArray } from "../../../blocks/navigationmenu/src/utils";
import { handleMomentDateFormat } from "./utils";
import moment from "moment";

interface Driver {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string | null;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
}

interface TransferFrom extends User { }

interface TransferTo {
  id: number;
  store_id: string;
  store_name: string;
  store_address: string;
}

interface TransferAttributes {
  id: number;
  transfer_id: string;
  station: string;
  created_at: string;
  amount: string;
  account_id: number;
  driver_account_id: number;
  transfer_from_id: number;
  transfer_from_type: string;
  transfer_to_id: number;
  transfer_to_type: string;
  status: string;
  driver_confirmed_at: string | null;
  driver_status: string;
  driver: Driver | null;
  created_by: User;
  transfer_from: TransferFrom;
  transfer_to: TransferTo;
  is_cashier: boolean;
  currency: string;
  variance: string;
  opening_cash: string;
  petty_cash: string;
  cash_from_orders: string;
  cash_in: string;
  cash_out: string;
  declare_cash: string;
  confirm_amount: string;
  reason: string;
  variance_amount: string;
  invoice_pdf: string | null;
}

export interface TransferRequest {
  id: string;
  type: string;
  attributes: TransferAttributes;
}

interface Meta {
  total_pages: number;
  total_count: number;
  current_page: number;
  next_page: number;
  pervious_page: number | null;
}

export interface EditField {
  amount: string;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  listType?: string
  permissionStatus?: PermissionStatus;
  searchFilter?: TransferRequest[];
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  page: number;
  pageSize: number;
  searchText: string;
  selectedTab: string;
  formFlag: boolean;
  listData: TransferRequest[];
  confirmAmtEditID: string;
  confirmAmtValue: string;
  listMetaData: Meta;
  sortingData: ISortingData;
  query: string;
  filterAnchor: HTMLDivElement | undefined;
  filters: IFilter[];
  permissionStatus: PermissionStatus;
  editID: string;
  editedData: EditField;
  storeList: unknown;
  regionIdQuery: string;
  storeIdQuery: string;
  editInputVisible: boolean;
  isListingDataLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class StoreKeeperController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  getTransactionListApiCallId: string = "";
  updateConfirmAmtApiCallId: string = "";
  editCashUpCallID: string = "";
  exportAllApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.BroadcastNavbarDataMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      editInputVisible: false,
      loading: false,
      page: 1,
      searchText: "",
      selectedTab: "Cash up",
      formFlag: false,
      listData: [],
      confirmAmtEditID: "",
      confirmAmtValue: "",
      pageSize: 10,
      listMetaData: {
        total_pages: 0,
        total_count: 1,
        current_page: 0,
        next_page: 0,
        pervious_page: null
      },
      sortingData: {
        "station": "",
        "date": "",
        "created_by.full_name": "",
        "opening_cash_date": "",
        "driver": "",
        "opening_cash": "",
        "petty_cash": "",
        "cash_from_orders": "",
        "cash_in": "",
        "cash_out": "",
        "declared_cash": "",
        "variance": "",
        amount: "",
        "transfer_id": "",
        "to_store_address": "",
        "from_store_id": "",
        "cashier_to": "",
        "to_store": "",
        "confirm_amount": "",
        "reason": ""
      },
      query: "",
      filterAnchor: undefined,
      filters: [
        {
          title: "Date",
          value: "",
          type: "dateselect",
          datevalue: { from: "", to: "" },
          options: [
            {
              label: "Today",
              value: "today",
            },
            {
              label: "Last 7 days",
              value: "last7",
            },
            {
              label: "Last 30 days",
              value: "last30",
            },
            {
              label: "Specific Dates",
              value: "specific",
            },
          ]
        }
      ],
      permissionStatus: {
        mainPermission: false,
        createPermission: false,
        viewPermission: false,
        editPermission: false,
        deactivatePermission: false
      },
      editID: "",
      editedData: {
        amount: '',
      },
      storeList:[],
      regionIdQuery: "",
      storeIdQuery: "",
      isListingDataLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    this.handleResForGetTransactionList(from, message)
    this.handleResUpdateCnfAmt(from, message)
    this.handleResForEditCashUp(from, message)
    this.handleResForExportAll(from,message)
    this.getBroadcastData(message)
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const paramId = this.props.navigation.getParam("navigationBarTitleText");
    this.handleTabChangeOnMount(paramId);
  }

  handleTabChangeOnMount = (paramId: string) => {
    if (paramId === "redirected") {
      this.setState({
        selectedTab: "Incoming Payments",
        isListingDataLoading: true,
      });
    } else {
      this.setState({
        selectedTab: "Cash up",
        isListingDataLoading: true,
      });
    }
  };

  async componentDidUpdate(prevProps: Props) {
    if (prevProps.searchFilter !== this.props.searchFilter && this.props.searchFilter) {
      this.setState({ listData: this.props.searchFilter, isListingDataLoading: false });
    }
  }

  handleSearch = (value: string) => {
    this.setState({ page: 1, searchText: value }, () => this.getTransactionList());
  };

  handleTabSeletion = (event: React.ChangeEvent<{}>, newValue: string) => {
    this.setState({ selectedTab: newValue, listData: [] },()=>this.getTransactionList());
  };

  handleAddCashUpFlag = () => {
    this.setState({ formFlag: !this.state.formFlag }, () => this.getTransactionList())
  }

  getType = (index: number, arrayLength: number) => {
    if (index === 0) {
      return 'left';
    } else if (index === arrayLength - 1) {
      return 'right';
    } else {
      return 'middle';
    }
  }

  handleConfirmAmtEdit = (data: TransferRequest) => {
    this.setState({ confirmAmtEditID: data.id, confirmAmtValue: data.attributes.confirm_amount, editInputVisible: true })
  }

  hanldeChangeConfirmAmt = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ confirmAmtValue: event.target.value })
  }

  handleSubmitConfAmtEdit = async (data: TransferRequest) => {
    this.setState({loading:true})
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {
        "status": "completed",
        "confirm_amount": this.state.confirmAmtValue,
        "id": data.id
      },
      header: headers,
      url: `${configJSON.updateStatusEndPoint}`,
      httpMethod: configJSON.patchMethod,
    });

    this.updateConfirmAmtApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTransactionList = async () => {
    this.setState({ isListingDataLoading: true });
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let requestType = sortCondition(this.props.listType as unknown as boolean || this.state.selectedTab == "Incoming Payments", "incoming", "outgoing")
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.getTransactionEndPoint}?request_type=${requestType}&page_no=${this.state.page}&per=${this.state.pageSize}${this.state.query}${sortCondition(this.state.searchText as unknown as boolean, "&filter_by[query]=" + this.state.searchText, "")}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}&request_page=store`,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.getTransactionListApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForGetTransactionList = async (from: string, message: Message) => {
    if (this.getTransactionListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        this.setState({ listData: responseJson.data, listMetaData: responseJson.meta })
      }
      this.setState({
        isListingDataLoading: false,
      });
    }
  };

  handleResUpdateCnfAmt = async (from: string, message: Message) => {
    if (this.updateConfirmAmtApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({loading:false})
      if (responseJson.status == 200) {
        this.setState({ confirmAmtEditID: '', confirmAmtValue: '', editInputVisible: false })
        this.getTransactionList()
      }
    }
  }

  handleTableRowsPerPage = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ pageSize: event.target.value as number, page: 1 }, () => {
      this.getTransactionList()
    });
  }

  handlePageChange = (page: number) => {
    this.setState({ page }, () => {
      this.getTransactionList()
    });
  };

  handleQueryChange = (query: string) => {
    this.setState({ query }, () => this.getTransactionList());
  };

  sortingProps = {
    onQueryChange: (query: string) => this.handleQueryChange(`&${query}`),
    onChange: (sortingData: ISortingData) => this.setState({ sortingData }),
  };

  handleFilterOpen = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    this.setState({ filterAnchor: event.currentTarget })
  }

  handleCloseFilterPopover = () => {
    this.setState({ filterAnchor: undefined })
  }


  handleFilterChangeAccount = (filters: IFilter[]) => {
    this.setState({
      filters,
      page: 1
    }, this.getTransactionList);
  };

  handleUserChange = (userContext: IUserContext) => {
    const apiKey = customPermissionApiKey.cashupPermission;
    const userData = userContext.user?.attributes.permission_groups;
    const value = checkForNewPermissonStatus(apiKey, userData as Array<PermissionGroupArray>);
    this.setState({
      permissionStatus: value,
      storeList: userContext.user?.attributes.employee_proifle.data.attributes.store_managements
    })
  };

  handleRedirectDetail = (item: TransferAttributes, index: number) => {
    if (index === 10 || (this.state.confirmAmtEditID === String(item.id) && index === 8)) {
      return
    }

    navigateTo({
      id: `cashup/${item.id}`,
      props: this.props,
      screenName: "OutGoingTransactionDetails",
    });

  }

  handleEditStoreKeeper = (data: TransferRequest) => {
    this.setState({
      editID: data.id, editedData: {
        amount: data.attributes.amount
      }
    })
  }

  handleChangeEditField = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ editedData: { ...this.state.editedData, [event.target.name]: event.target.value } })
  }

  editCashUp = async (dataID: string) => {
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: this.state.editedData,
      header: headers,
      url: `${configJSON.transferRequestsListApi}/${dataID}`,
      httpMethod: configJSON.putMethod,
    });

    this.editCashUpCallID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForEditCashUp = async (from: string, message: Message) => {
    if (this.editCashUpCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status == 200) {
        this.setState({ editID: "" })
        this.getTransactionList()
      }
    }
  }

  handleDateFilterParam = () => {
    const today = moment().format('YYYY-MM-DD');
    let comomStartDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.value;
    let commonEndDate = today;
    let startDate, endDate;
    switch (dateFilter) {
      case 'last7':
        endDate = commonEndDate;
        startDate = comomStartDate;
        break;
      case 'today':
        startDate = today;
        endDate = today;
        break;
      case 'specific':
        const dateFilter = this.state.filters.find((item) => item?.title === 'Date')?.datevalue;
        if (dateFilter?.from && dateFilter?.to) {
          endDate = moment(dateFilter.to).format('YYYY-MM-DD');
          startDate = moment(dateFilter.from).format('YYYY-MM-DD');
        }
        break;
      case 'last30':
        startDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
        endDate = today;
        break;
      default:
        endDate = today;
        startDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    }
    const startDateText = startDate ? handleMomentDateFormat(startDate, 'DD-MM-YYYY') : moment().subtract(7, 'days').format('DD-MM-YYYY')
    const endDateText = endDate ? handleMomentDateFormat(endDate, 'DD-MM-YYYY') : moment().format('DD-MM-YYYY')

    const dateFilterQuery = startDate && endDate
      ? `&filter_by[created_at]=true&filter_by[start_date]=${startDate}&filter_by[end_date]=${endDate}`
      : '';

    return { startDateText, dateFilterQuery, endDateText }
  };

  handleRedirectDetailFromCashup = (item: TransferAttributes, index: number) => {
    if (index === 11 || (this.state.editID === String(item.id) && index === 10)) {
      return
    }

    navigateTo({
      id: `cashup/${item.id}`,
      props: this.props,
      screenName: "OutGoingTransactionDetails",
    });

  }

  exportAll = async () => {
    const dateFilterQuery = this.handleDateFilterParam().dateFilterQuery
    let requestType = sortCondition(this.props.listType as unknown as boolean || this.state.selectedTab == "Incoming Payments", "incoming", "outgoing")
    let headers = {
      token: await getStorageData('token'),
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = apiCall({
      httpBody: {},
      header: headers,
      url: `${configJSON.exportAllEndpoint}?request_type=${requestType}${dateFilterQuery}${this.state.regionIdQuery}${this.state.storeIdQuery}&request_page=store`,
      httpMethod: configJSON.validationApiMethodType,
    });

    this.exportAllApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForExportAll = async (from: string, message: Message) => {
    if (this.exportAllApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.data) {
        location.href = responseJson.data.url;
      }
    }
  }

  handlePrintInvoice = (fileUrl?: string | null) => {
    const printMessage = new Message(getCustomEnumName(CustomEnums.CustomActionReducers))
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerAction), "PRINT_FILE")
    printMessage.addData(getCustomEnumName(CustomEnums.CustomReducerPayload), {
      format: "pdf",
      data: fileUrl
    })
    this.send(printMessage)
  }

  getBroadcastData = (message: Message) => {
    if (message.id === getName(MessageEnum.BroadcastNavbarDataMessage)) {
      const recievedData = message.getData(
        getName(MessageEnum.BroadcastNavbarData)
      );
      if(recievedData.storeId){
        this.handleStoreChange(recievedData.storeId)
      }
      if(recievedData.regionMultiId){
        this.handleRegionChange(recievedData.regionMultiId)
      }
    }
  }

  handleRegionChange = (value: string) => {
    this.setState({regionIdQuery:`&filter_by[region_ids]=${value}`},()=>this.getTransactionList())
  }

  handleStoreChange = (value: string) => {
    this.setState({storeIdQuery:`&filter_by[store_ids]=${value}`},()=>this.getTransactionList())
  }

  handleDirectConfirmAmt = (event: React.MouseEvent<HTMLButtonElement>, data: TransferRequest) => {
    event.stopPropagation()
    this.setState({ confirmAmtValue: data.attributes.amount }, () => this.handleSubmitConfAmtEdit(data))
  }

  getTableRowColumns(incomingTableType:boolean = false) {
    const currentScreenWidth = window.innerWidth;
    const cashupTableColumnConfigs = [
      { maxWidth: 1024, columnLayout: "85px 80px 110px 100px 75px 93px 70px 80px 68px 68px 95px 93px" },
      { maxWidth: 1280, columnLayout: "92px 90px 116px 106px 82px 100px 79px 89px 78px 78px 102px 100px" },
      { maxWidth: Infinity, columnLayout: "1fr 100px 1fr 100px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr" }
    ];
    const incomingPaymentTableColumnConfigs = [
      { maxWidth: 1024, columnLayout: `113px 90px 70px 106px 85px 60px 75px 88px ${sortCondition(this.state.editInputVisible, "140px", "90px")} 93px 84px` },
      { maxWidth: 1280, columnLayout: `1fr 1fr 1fr 106px 1fr 1fr 1fr 1fr ${sortCondition(this.state.editInputVisible, "160px", "1fr")} 1fr 1fr` },
      { maxWidth: Infinity, columnLayout: `1fr 1fr 1fr 106px 1fr 1fr 1fr 1fr ${sortCondition(this.state.editInputVisible, "204px", "1fr")} 1fr 1fr` }
    ];
    const getColumnLayout = (screenConfigs: { maxWidth: number; columnLayout: string }[]) =>
      screenConfigs.find(config => currentScreenWidth <= config.maxWidth)?.columnLayout;
    const mainTableColumns = getColumnLayout(incomingTableType ? incomingPaymentTableColumnConfigs : cashupTableColumnConfigs);
    return `${mainTableColumns}`.trim();
  }

  // Customizable Area End
}
